<template>
    <base-dialog :width="dialogWidth" :is-open.sync="dialog">
        <v-card-title style="background-color: #f1eeee">
            <span style="color: #78756a" class="font-weight-bold">View </span>
            <v-btn
                v-if="profile !== 'Requests'"
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#389877"
                @click="checkProfile(profile)"
                >Update</v-btn
            >
            <v-btn
                class="border-radius-25 px-5 ml-5"
                depressed
                rounded
                dark
                color="#983838"
                @click="isDeletingUser.showModal = true"
                >Delete</v-btn
            >
        </v-card-title>

        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-container fluid>
            <v-row v-if="profile === 'Users'">
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Personal</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Prefix</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.prefix)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >First Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.first_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Middle Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.middle_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Last Name</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.last_name)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Suffix</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.suffix)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Nickname</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.nickname)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Birthdate</label
                        ><span style="width: 50%">{{
                            $checkContent(
                                new Date(
                                    itemData.birthdate
                                ).toLocaleDateString()
                            )
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Gender</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.gender)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Marital Status</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.marital_status)
                        }}</span>
                    </div>
                </v-col>
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Contact</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Address</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.address)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Contact Number</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.contact_number)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Email Address</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.email)
                        }}</span>
                    </div>
                    <span
                        class="ml-4 mt-5 text-md font-weight-bold section-header"
                        >Work</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Occupation</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.occupation)
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Company</label
                        ><span style="width: 50%">{{
                            $checkContent(itemData.company)
                        }}</span>
                    </div>
                </v-col>
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <span class="ml-4 text-md font-weight-bold section-header"
                        >Membership</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Status</label
                        ><span style="width: 50%">{{
                            itemData.user_information.membership.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Designation</label
                        ><span style="width: 50%">{{
                            getInfo("designation")
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >User Type</label
                        ><span style="width: 50%">{{ getInfo("role") }}</span>
                    </div>
                    <span
                        class="ml-4 mt-5 text-md font-weight-bold section-header"
                        >Family</span
                    >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Father</label
                        ><span style="width: 50%">{{ getInfo("father") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Mother</label
                        ><span style="width: 50%">{{ getInfo("mother") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Sibling(s)</label
                        ><span style="width: 50%">{{
                            getInfo("siblings")
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Spouse</label
                        ><span style="width: 50%">{{ getInfo("spouse") }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 50%; color: #78756a"
                            class="font-weight-bold"
                            >Children</label
                        ><span style="width: 50%">{{
                            getInfo("children")
                        }}</span>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="profile === 'Groups'">
                <v-col
                    md="12"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Group Type</label
                        ><span style="width: 70%">{{
                            itemData.group_category.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Group Name</label
                        ><span style="width: 70%">{{ itemData.name }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Description</label
                        ><span style="width: 70%">{{
                            itemData.group_category.description
                        }}</span>
                    </div>
                    <div class="d-flex pl-8">
                        <label
                            style="width: 30%; color: #369776"
                            class="font-weight-bold"
                            >Members
                            <span>
                                <v-btn
                                    x-small
                                    depressed
                                    dark
                                    fab
                                    color="#389877"
                                    class="add-btn ml-2"
                                    @click="isSearchMemberDialogOpen = true"
                                    ><v-icon small
                                        >mdi-plus-thick</v-icon
                                    ></v-btn
                                >
                            </span></label
                        >

                        <div style="width: 70%" class="members-container">
                            <div
                                style="width: 100%"
                                v-for="member in groupMembers"
                                :key="member.id"
                                class="inner-member pr-7"
                            >
                                <span>
                                    {{
                                        `${member.user.first_name} ${member.user.last_name}`
                                    }}</span
                                >
                                <v-btn
                                    depressed
                                    rounded
                                    dark
                                    small
                                    color="#983838"
                                    class="px-6"
                                    @click="onDeleteMember(member)"
                                    >Delete</v-btn
                                >
                            </div>
                            <span v-if="!getInfo('members')">None</span>
                        </div>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="profile === 'Requests'">
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-4">
                        <label
                            style="width: 50%; color: #369776"
                            class="font-weight-bold"
                            >Request Type</label
                        ><span style="width: 50%; text-transform:capitalize">{{
                            itemData.request_type.name
                        }}</span>
                    </div>
                    <div class="d-flex pl-4">
                        <label
                            style="width: 50%; color: #369776"
                            class="font-weight-bold"
                            >Requested By</label
                        ><span style="width: 50%">{{
                            `${itemData.requested_by.first_name} ${itemData.requested_by.last_name}`
                        }}</span>
                    </div>
                    <div class="d-flex pl-4">
                        <label
                            style="width: 50%; color: #369776"
                            class="font-weight-bold"
                            >Submitted On</label
                        ><span style="width: 50%">{{
                            new Date(itemData.created_at).toLocaleString()
                        }}</span>
                    </div>
                </v-col> 
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-4">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Request Details</label
                        >
                    </div>   
                    <v-col 
                        v-if="itemData.request_type.id === 1"
                        md="12"
                        rows="12"
                        class="d-flex flex-column item-column mt-n3">
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Social Media Link</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.social_media_link)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Preferred Language</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.language)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="width: 50%; color: #78756a"
                                class="font-weight-bold"
                                >School, Year, Course (if studying)</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.school_year_course)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Occupation and Company (if working)</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.occupation_and_company)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Preferred Group Composition</label
                            ><br><span class="pl-4">{{
                                itemData.preferred_group_composition_users.length > 0 ? $checkContent(itemData.preferred_group_composition_users[0].composition.name) : "--"
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Availability</label
                            ><br><span class="pl-4">{{
                                itemData.member_availabilities.length > 0 ? $checkContent(itemData.member_availabilities[0].day.name) + " - " + $checkContent(itemData.member_availabilities[0].schedule.name) : "--"
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Do you prefer to be part of a NEW small group (as opposed to joining an existing small group)?</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.small_group_option.option)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Additional Comments</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.additional_comments)
                            }}</span>
                        </div>
                    </v-col> 
                    <v-col 
                        v-else-if="itemData.request_type.id === 2"
                        md="12"
                        rows="12"
                        class="d-flex flex-column item-column mt-n3">
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Preferred Date and Time</label
                            ><span style="width: 40%">{{
                                new Date(itemData.preferred_datetime).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Purpose of Visitation</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.purpose_of_visitation)
                            }}</span>
                        </div>
                    </v-col>
                    <v-col 
                        v-if="itemData.request_type.id === 3"
                        md="12"
                        rows="12"
                        class="d-flex flex-column item-column mt-n3">
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Name</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.name)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Contact Number</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.contact_number)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="width: 50%; color: #78756a"
                                class="font-weight-bold"
                                >GCF Member</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.gcf_member)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Deceased Name</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.deceased_name)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Deceased Date of Birth</label
                            ><br><span class="pl-4">{{
                                new Date(itemData.deceased_date_of_birth).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Deceased Date of Death</label
                            ><br><span class="pl-4">{{
                                new Date(itemData.deceased_date_of_death).toLocaleString("en-US", { year: 'numeric', month: 'long', day: 'numeric'})
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >Where will the funeral service be held?</label
                            ><br><span class="pl-4">{{
                                $checkContent(itemData.funeral_service_location)
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >What is your preferred date & time for the funeral service?</label
                            ><br><span class="pl-4">{{
                                new Date(itemData.funeral_service_preferred_date_time).toLocaleString("en-US", { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })
                            }}</span>
                        </div>
                        <div class="pl-8">
                            <label
                                style="color: #78756a"
                                class="font-weight-bold"
                                >How would vou like the service to be conducted?</label
                            ><br>
                            <span v-if="itemData.online === 1 && itemData.on_site === 1" class="pl-4">
                                Both (On-site & Online)
                            </span>
                            <span v-else-if="itemData.online === 1 && itemData.on_site !== 1" class="pl-4">
                                Online
                            </span>
                            <span v-if="itemData.online !== 1 && itemData.on_site === 1" class="pl-4">
                                On-site
                            </span>
                        </div>
                    </v-col> 
                    <v-col 
                        v-else-if="itemData.request_type.id === 4"
                        md="12"
                        rows="12"
                        class="d-flex flex-column item-column mt-n3">
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Name</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.name)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Age</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.age)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Gender</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.gender)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Contact Number</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.contact_number)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Occupation</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.occupation)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Church Affiliate</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.church_affiliation)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >GCF Member</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.gcf_member)
                            }}</span>
                        </div>
                        <div class="d-flex pl-4">
                            <label
                                style="width: 60%; color: #78756a"
                                class="font-weight-bold"
                                >Presenting Problems</label
                            ><span style="width: 40%">{{
                                $checkContent(itemData.presenting_problem)
                            }}</span>
                        </div>
                    </v-col>
                </v-col>    
                <v-col
                    md="4"
                    rows="12"
                    class="px-5 d-flex flex-column item-column"
                >
                    <div class="d-flex pl-4">
                        <label
                            style="width: 40%; color: #369776"
                            class="font-weight-bold"
                            >Update Status</label
                        > 
                    </div>  
                    <div class="d-flex ml-8 mr-12">
                        <base-input-label style="width: 42%" required>Status</base-input-label>
                        <v-select 
                            style="width: 60px"
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            item-value="id"
                            item-text="name"
                            :items="requestStatuses"
                            v-model="form.status"
                        ></v-select>
                    </div>  
                    <div class="d-flex mt-4">
                        <v-row align="center" justify="center">
                            <v-col md="2" cols="12">
                                <v-btn
                                    class="border-radius-25 px-7"
                                    block
                                    depressed
                                    dark
                                    color="#389877"
                                    rounded
                                    :loading="isActionStart"
                                    @click="onSaveRequestStatus"
                                    >Save</v-btn
                                >
                            </v-col>
                        </v-row>
                    </div>  
                </v-col> 
            </v-row>
        </v-container>
        <base-dialog :width="500" :is-open.sync="isDeletingUser.showModal">
            <v-card-title style="background-color: #f1eeee">
                <span style="color: #78756a" class="font-weight-bold"
                    >Delete {{ deleteSubject() }}</span
                >
                <v-container fluid>
                    <div
                        class="d-flex flex-column justify-center align-center h-100 w-100"
                    >
                        <span class="font-size-16 mt-2"
                            >Are you sure you want to delete this {{ deleteSubject().toLowerCase() }}?</span
                        >
                    </div>
                    <v-card-actions class="">
                        <v-container fluid>
                            <v-row justify="end" class="action-row">
                                <v-btn
                                    class="border-radius-25 px-5 mr-3"
                                    depressed
                                    rounded
                                    dark
                                    color="secondary"
                                    @click="isDeletingUser.showModal = false"
                                    >Cancel</v-btn
                                >
                                <v-btn
                                    class="border-radius-25 px-5"
                                    depressed
                                    rounded
                                    dark
                                    :loading="isDeletingUser.loading"
                                    color="#983838"
                                    @click="deleteItem(loadedProfile.id)"
                                    >Delete</v-btn
                                >
                            </v-row>
                        </v-container></v-card-actions
                    >
                </v-container>
            </v-card-title>
        </base-dialog>
        <MemberModal
            v-if="showMemberModal"
            :show="showMemberModal"
            @close="showMemberModal = false"
            :group_id="itemData.id"
            @getData="updateGroup"
            :groupMethod="groupMethod"
            :currentMember="currentMember"
            @deleteUser="deleteUser"
            :groupMembers="groupMembers"
        />

        <custom-search-dialog
            :is-open.sync="isSearchMemberDialogOpen"
            title="Search Users"
            :action="getMembersAction"
            @onSelect="onSelectMember"
        >
            <template v-slot:text="{ item }">
                {{ item.prefix }} {{ item.first_name }} {{ item.last_name }}
            </template>
        </custom-search-dialog>
    </base-dialog>
</template>

<script>
import MemberModal from "./member-modal.vue";
import BaseDialog from "@/components/Base/BaseDialog";
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import CustomSearchDialog from "@/components/Custom/CustomSearchDialog";
import { GET_USERS, DELETE_USER } from "@/store/modules/user";
import { ADD_GROUP_MEMBER, DELETE_GROUP_MEMBER, DELETE_GROUP } from "@/store/modules/group";
import { UPDATE_REQUEST_STATUS, DELETE_REQUEST } from "@/store/modules/request";

export default {
    name: "ministry-information-dialog",

    props: {
        show: { type: Boolean, default: false, required: true },
        selectedProfile: String,
        loadedProfile: Object,
    },

    data() {
        return {
            dialog: this.show,
            showMemberModal: false,
            groupMethod: "",
            currentMember: "",
            isSearchMemberDialogOpen: false,
            getMembersAction: GET_USERS,
            loadedProfileLocal: Object.assign({}, this.loadedProfile),
            isDeletingUser: {
                loading: false,
                showModal: false,
            },
            error: null,
            isActionStart: false,
            form: { 
                status: null,
            },
            requestID: null,
        };
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        dialog(to) {
            if (!to) {
                this.$emit("close");
            }
        },
    },

    methods: {

        async onSaveRequestStatus() {
            const payload = {
                requestID: this.requestID,
                status: this.form.status,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_REQUEST_STATUS,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.$emit("updateTable");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Ministry",
                    text: "Request was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        resetForm() {
            this.error = null;
            this.form.status = null;
        },

        showModal(method, member) {
            this.showMemberModal = true;
            this.groupMethod = method;
            this.currentMember = member;
        },
        updateGroup() {
            this.$emit("getGroup", this.itemData.id);
        },
        checkProfile(profile) {
            switch (profile) {
                case "Groups":
                    // console.log("open group form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
                    break;
                case "Requests":
                    // console.log("open request form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
                    break;
                default:
                    // console.log("open user form modal")
                    this.$emit("showUpdateForm", {
                        profile: profile,
                        data: this.itemData,
                    });
            }
            // console.log("checking");
        },

        deleteItem(id) { 
            if (this.profile === 'Users') {
                this.deleteUser(id);
            }
            else if (this.profile === 'Groups') {
                this.deleteGroup(id);
            }
            else if (this.profile === 'Requests') {
                this.deleteRequest(id);
            }
        },

        deleteUser(id) {
            this.isDeletingUser.loading = true;
            this.$store
                .dispatch(DELETE_USER, id)
                .then(() => {
                    this.$emit("onSuccess");
                    this.$emit("updateTable");
                    this.isDeletingUser.loading = false;
                    this.isDeletingUser.showModal = false;
                    this.dialog = false;
                    this.$notify({
                        group: "main-notifications",
                        type: "success",
                        title: "Delete User",
                        text: "User successfully deleted",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteGroup(id) {
            this.isDeletingUser.loading = true;
            this.$store
                .dispatch(DELETE_GROUP, id)
                .then(() => {
                    this.$emit("onSuccess");
                    this.$emit("updateTable");
                    this.isDeletingUser.loading = false;
                    this.isDeletingUser.showModal = false;
                    this.dialog = false;
                    this.$notify({
                        group: "main-notifications",
                        type: "success",
                        title: "Delete Group",
                        text: "Group successfully deleted",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        deleteRequest(id) {
            this.isDeletingUser.loading = true;
            this.$store
                .dispatch(DELETE_REQUEST, id)
                .then(() => {
                    this.$emit("onSuccess");
                    this.$emit("updateTable");
                    this.isDeletingUser.loading = false;
                    this.isDeletingUser.showModal = false;
                    this.dialog = false;
                    this.$notify({
                        group: "main-notifications",
                        type: "success",
                        title: "Delete Request",
                        text: "Request successfully deleted",
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        closeForm() {
            this.$emit("close");
        },
        getFamily(type) {
            const familyItem = [];
            this.itemData.user_relationships.map((item) => {
                if (item.relationship.name === type) {
                    familyItem.push(
                        `${item?.relate_user.first_name} ${item?.relate_user.last_name}`
                    );
                }
            });
            if (familyItem.length === 0) {
                return "--";
            }
            return familyItem.join(", ");
        },
        getInfo(type) {
            let outArr = [];
            if (type === "designation") {
                this.itemData.user_designations.forEach((item) => {
                    outArr.push(item.designation.name);
                });
                return outArr.join(", ");
            }
            if (type === "role") {
                this.itemData.user_roles.forEach((item) => {
                    outArr.push(item.role.name);
                });
                return outArr.join(", ");
            }
            if (type === "father") {
                return this.getFamily("Father");
            }
            if (type === "mother") {
                return this.getFamily("Mother");
            }
            if (type === "spouse") {
                return this.getFamily("Spouse");
            }
            if (type === "siblings") {
                return this.getFamily("Sibling");
            }
            if (type === "children") {
                return this.getFamily("Child");
            }
            if (type === "members") {
                const members = [];
                this.itemData.user_groups.forEach((item) => {
                    members.push(
                        `${item.user.first_name} ${item.user.last_name}`
                    );
                });
                if (members.length === 0) {
                    return false;
                }
                return members;
            }
        },

        async onSelectMember(member) {
            const existingMembers = this.itemData.user_groups.map(
                (user) => user.user.id
            );
            if (existingMembers.includes(member.id)) {
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    title: "Existing Group Member",
                    text: `${member.first_name} ${member.last_name} was already in the list.`,
                });
                return;
            }
            const payload = {
                groupID: this.itemData.id,
                user_ids: [member.id],
            };
            const { success } = await this.$store.dispatch(
                ADD_GROUP_MEMBER,
                payload
            );
            if (success) {
                this.loadedProfileLocal = Object.assign(
                    {},
                    {
                        ...this.loadedProfileLocal,
                        user_groups: [
                            ...this.loadedProfileLocal.user_groups,
                            { user: Object.assign({}, member) },
                        ],
                    }
                );
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "New Group Member Added",
                    text: `${member.first_name} ${member.last_name} Added.`,
                });
                return;
            }
            this.$notify({
                group: "main-notifications",
                type: "error",
                title: "Group Member",
                text: "Something went wrong.",
            });
        },

        async onDeleteMember(member) {
            const user = member.user;
            const payload = {
                groupID: this.itemData.id,
                user_ids: [user.id],
            };
            const { success } = await this.$store.dispatch(
                DELETE_GROUP_MEMBER,
                payload
            );
            if (success) {
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Group Member",
                    text: `${user.first_name} ${user.last_name} Deleted.`,
                });
                this.loadedProfileLocal = Object.assign(
                    {},
                    {
                        ...this.loadedProfileLocal,
                        user_groups: this.loadedProfileLocal.user_groups.filter(
                            (_member) => _member.user.id !== user.id
                        ),
                    }
                );
                return;
            }
            this.$notify({
                group: "main-notifications",
                type: "error",
                title: "Group Member",
                text: "Something went wrong.",
            });
        },

        capitalize(string) { 
            const words = string.split(" ");
            return  words.map((word) => { 
                return word[0].toUpperCase() + word.substring(1); 
            }).join(" ");
        },

        deleteSubject() {
            if (this.profile === 'Users') {
                return "User";
            }
            else if (this.profile === 'Groups') {
                return "Group";
            }
            else if (this.profile === 'Requests') {
                return "Request";
            }
            return "";
        }
    },

    computed: {
        users() {
            return this.allUsers;
        },
        profile() {
            return this.selectedProfile;
        },
        dialogWidth() {
            if (this.selectedProfile === "Users") {
                return 1350;
            } else if (this.selectedProfile === "Groups") {
                return 650;
            } else {
                return 1350;
                // return 600;
            }
        },
        itemData() {
            return this.loadedProfileLocal;
        },
        groupMembers() {
            return this.itemData.user_groups;
        },
        requestStatuses() {
            return this.$store.state.profile.requestStatuses;
        },
    },

    mounted() {
        if (this.selectedProfile === 'Requests') { 
            this.form.status = this.capitalize(this.itemData.status == "Completed" ? "confirmed" : this.itemData.status.toLowerCase())
            this.requestID = this.itemData.id;
        }
    },

    components: { CustomSearchDialog, BaseDialog, BaseInputLabel, MemberModal },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.section-header {
    color: #389877;
}

.item-column {
    gap: 20px;
}

.members-container {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0 10px 0;
}

.inner-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-btn {
    width: 25px !important;
    height: 25px !important;
}

.action-row {
    margin-top: 5px;
    margin-bottom: -50px;
}
</style>
