<template>
  <v-dialog
    v-model="show"
    scrollable
    :width="method === 'add' ? 600 : 400"
    color="primaryBackgroundColor"
    transition="scroll-y-reverse-transition"
    persistent
  >
    <v-card id="form-dialog" class="px-0 py-3 pb-7" style="background-color: #f1eeee">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="dialog-close close-icon" v-on="on" @click="closeForm"
            >close</v-icon
          >
        </template>
        <span>Close</span>
      </v-tooltip>
      <v-card-title style="background-color: #f1eeee" class="px-0">
        <v-card-text style="color: #369776; font-weight: 700; font-size: 20px">{{
          method === "add" ? "Add Members" : "Remove Member"
        }}</v-card-text>
      </v-card-title>
      <v-container fluid class="px-10 py-0">
        <v-autocomplete
          v-if="method === 'add'"
          v-model="formData.members"
          label="Members"
          multiple
          filled
          outlined
          :items="allUsers"
          :no-data-text="noDataText"
          dense
          color="primaryTextColor"
          hide-selected
        >
          <template v-slot:selection="{ item, parent, selected }">
            <v-chip
              :input-value="selected"
              label
              small
              class="white--text"
              color="primaryTextColor"
              @click.native.stop
            >
              <span>{{ item }}</span>
              <v-icon small right @click="parent.selectItem(item)">mdi-close</v-icon>
            </v-chip>
          </template>
        </v-autocomplete>
        <span v-else class="mb-5">{{
          `Remove user ${member.user.first_name} ${member.user.last_name} from group?`
        }}</span>
      </v-container>
      <v-card-actions class="pa-0">
        <v-container fluid class="py-0">
          <v-row justify="end" class="px-10">
            <v-col md="3" cols="12" :class="method === 'add' ? 'px-2' : 'mt-4'">
              <v-btn block depressed dark color="#389877" rounded @click="onSubmit">{{
                method === "add" ? "Add Members" : "Remove"
              }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, default: false, required: true },
    profile: String,
    selectedIndex: Object,
    group_id: Number,
    groupMethod: String,
    currentMember: Object,
    groupMembers: Array,
  },
  data() {
    return {
      formData: {
        loading: true,
        members: [],
      },
      allUsers: [],
      userData: {
        loading: true,
      },
      noDataText: "",
    };
  },
  watch: {
    show(to) {
      this.dialog = to;
    },
    dialog(to) {
      if (!to) {
        this.$emit("close");
      }
    },
    userData(data) {
      if (!data.loading) {
        let existingMemberIds = [];

        this.groupMembers.map((item) => {
          existingMemberIds.push(item.user.id);
        });

        let viableUsers = this.userData.data.filter(
          (user) => !existingMemberIds.includes(user.id)
        );

        viableUsers.map((item) => {
          this.allUsers.push(`${item.first_name} ${item.last_name}`);
        });

        if (viableUsers.length <= 0) {
          this.noDataText = "No Members Available."  
        }
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.groupMethod === "add") {
        let membersArr = [];
        let memberIds = [];
        if (this.formData.members.length > 0) {
          this.formData.members.forEach((item) => {
            membersArr.push({
              first_name: item.split(" ")[0],
              last_name: item.split(" ")[1],
            });
          });

          membersArr.map((item) => {
            memberIds.push(
              this.userData.data.find(
                (user) =>
                  user.first_name === item.first_name && user.last_name === item.last_name
              ).id
            );
          });
        }

        if (memberIds.length <= 0) {
          this.$notify({
            group: "main-notifications",
            type: "error",
            title: "No input",
            text: "Please select a member",
          });
        } else {
          this.$store
            .dispatch("profile_groups/addUserToGroup", {
              id: this.group_id,
              data: {
                user_ids: [...memberIds],
              },
            })
            .then((response) => {
              this.$notify({
                group: "main-notifications",
                type: "success",
                title: "Member Added",
                text: response.message,
              });
              this.$emit("getData");
              this.$emit("close");
            })
            .catch((error) => {
              this.$notify({
                group: "main-notifications",
                type: "error",
                title: error.message,
                text: error.response.data.message,
              });
            });
        }
      } else {
        this.$emit("deleteUser", this.group_id, this.member.user.id);
        this.$emit("close");
      }
    },
    closeForm() {
      this.$emit("close");
    },
    getUsersList() {
      this.$api.main.cancelCurrentApiCall();
      this.userData.loading = true;
      this.noDataText = "Loading Users" 
      this.$store
        .dispatch("profile_users/fetchUsers")
        .then((response) => {
          this.userData = response;
          this.userData.loading = false;
        })
        .catch((error) => {
          this.$notify({
            group: "main-notifications",
            type: "error",
            duration: 5000,
            title: "Error Loading Users",
            text: error.response.data.message,
          });
        });
    },
  },
  computed: {
    users() {
      return this.allUsers;
    },
    member() {
      return this.currentMember;
    },
    method() {
      return this.groupMethod;
    },
  },
  mounted() {
    this.getUsersList();
  },
};
</script>

<style lang="scss" scoped></style>
