<template>
    <div class="mt-3">
        <v-row justify="space-between">
            <v-col cols="2" align-self="center" class="text-left">
                <v-btn
                    :disabled="pages[0] === selected"
                    @click="selected--"
                    text
                    small
                    color="success"
                    ><v-icon color="secondary">mdi-arrow-left-thin</v-icon
                    >Previous</v-btn
                >
            </v-col>
            <v-col cols="8" class="text-center">
                <v-btn
                    @click="selected = page"
                    text
                    x-small
                    v-for="page in pagesToDisplay"
                    :key="page"
                    :color="selected === page ? 'success' : 'secondary'"
                    class="font-weight-bold"
                >
                    {{ page }}
                </v-btn>
                <!-- {{ pagesToDisplay }} -->
            </v-col>
            <v-col cols="2" align-self="center" class="text-right">
                <v-btn
                    :disabled="pages[pages.length - 1] === selected"
                    @click="selected++"
                    text
                    small
                    color="success"
                    >Next<v-icon color="secondary"
                        >mdi-arrow-right-thin</v-icon
                    ></v-btn
                >
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    name: "pagination",

    props: {
        totalPages: { type: Number, default: 0, required: true },
        currentPage: { type: Number, default: 0, required: true },
    },

    data() {
        return {
            selected: this.currentPage,
        };
    },

    watch: {
        selected(page) {
            this.$emit("change", page);
        },
    },

    computed: {
        pages() {
            let pages = [];
            for (let index = 1; index < this.totalPages + 1; index++) {
                pages.push(index);
            }
            return pages;
        },

        pagesToDisplay() {
            let pages = [];
            if (this.pages.length >= 5) {
                if (this.pages.slice(0, 2).includes(this.selected)) {
                    pages = this.pages.slice(0, 5);
                } else if (this.pages.slice(-2).includes(this.selected)) {
                    pages = this.pages.slice(-5);
                } else {
                    pages = this.pages.slice(
                        this.selected - 3,
                        this.selected + 2
                    );
                }
            } else {
                pages = this.pages;
            }

            return pages;
        },
    },
};
</script>
